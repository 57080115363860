.c-list__table {
    width: 100%;
    margin: 20px 0;
  }
  
  .__title {
    display: flex;
    align-items: center;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .__title-icon {
    margin-right: 8px;
  }
  
  .c-list__table-structure {
    overflow-x: auto;
  }
  
  .mat-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .mat-header-row, .mat-row {
    display: table-row;
  }
  
  .mat-header-cell, .mat-cell {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
  }
  
  .mat-header-cell {
    font-weight: bold;
    position: sticky;
    top: 0;
    z-index: 100;
  }
  
  .mat-table-sticky {
    position: sticky;
    bottom: 0;
    z-index: 10;
  }
  
  .u-nowrap {
    white-space: nowrap;
  }
  
  .c-audit-change {
    margin-top: 10px;
  }
  
  .c-audit-change__title {
    display: block;
    margin-bottom: 5px;
  }
  
  .u-capitalize {
    text-transform: capitalize;
  }
  
  .u-capitalise {
    text-transform: capitalize;
  }
  
  .is-over-content {
    position: relative;
  }
  
  .is-hidden {
    display: none;
  }
  
